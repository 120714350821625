import shecodesLogo from "../images/logos/shecodesLogo.png";
import freecodecamplogo from "../images/logos/FreeCodeCampLogo.png";
import javascript from "../images/logos/jsimage.png";
import github from "../images/logos/github.png";
import reactlogo from "../images/logos/react.png";
import git from "../images/logos/giticon.png";
import jira from "../images/logos/jira.png";
import node from "../images/logos/node.png";
import udemy from "../images/logos/udemy.png";
import mongoDB from "../images/logos/mongoDB.png";
import express from "../images/logos/express.png";
import graphQL from "../images/logos/graphQL.png";
import apollo from "../images/logos/apollo.png";
import docker from "../images/logos/docker.png";
import aws from "../images/logos/aws.png";
import htmlcss from "../images/logos/htmlcss.png";
import jest from "../images/logos/jest.png";
export const codingCertifications = [
  {
    company: "Udemy",
    logo: udemy,
    width: 110,
    certifications: [
      {
        name: "Complete NodeJS Developer in 2022 (GraphQL, MongoDB, + more)",
        link: "https://https://www.udemy.com/certificate/UC-9d2f5f48-6d4d-45e6-ac19-b55a02cb1bbb/.udemy.com",
        completed: true,
      },
      {
        name: "Git & Github Bootcamp",
        link: "https://www.ude.my/UC-b50c0748-be16-44d9-a71e-cde5fd0ce1ee/",
        completed: true,
      },
      {
        name: "Linux Command Line Bootcamp: Beginner to Power User",
        link: "https://www.udemy.com/certificate/UC-e69c5abb-802f-42b6-b2b7-b27ad3ee8d4b/",
        completed: true,
      },
      {
        name: "Complete Javascript Course 2022: From Zero to Expert",
        link: "https://www.udemy.com/certificate/UC-20f05786-ac50-49df-9096-89bab430f473/",
        completed: true,
      },
    ],
  },
  {
    company: "SheCodes",
    logo: shecodesLogo,
    width: 140,
    certifications: [
      {
        name: "React Development",
        link: "https://www.shecodes.io/certificates/bc6a5676d5c7ef917281bc3fea661824",
        completed: true,
      },
      {
        name: "Responsive Web Development",
        link: "https://www.shecodes.io/certificates/e9d1ffc118ff54751535e44a39b4b03e",
        completed: true,
      },
      {
        name: "Front End Development",
        link: "https://www.shecodes.io/certificates/3bf1263b96b4a72924b46025cbe81c00",
        completed: true,
      },
    ],
  },
  {
    company: "FreeCodeCamp",
    logo: freecodecamplogo,
    width: 140,
    certifications: [
      {
        name: "Responsive Web Design",
        link: "https://freecodecamp.org/certification/karenstewart/responsive-web-design",
        completed: true,
      },
      {
        name: "Javascript Algorithms & Data Structures",
        link: "https://www.freecodecamp.com",
        completed: true,
      },
      {
        name: "Front End Development Libraries",
        link: "https://www.freecodecamp.com",
        completed: false,
      },
    ],
  },
];

export const careerChange = {
  title: "Why did I change careers?",
  paragraph: `I was working as a lawyer at a media production company when I was
              tasked with helping to create an app for our legal teams. Over
              several months, I worked closely with the developers and it really
              sparked my interest. Working on this project was the most exciting
              part of my day. I signed up for a coding course and was hooked!
              Ever since, I have been developing my skills through self-study,
              taking courses and building projects.`,
};

export const technologies = {
  title: "Technologies",
  paragraph: `The technologies that interest me most are Node and React. I'm fascinated by all the things I can do with Javascript on the front end and the back end. While improving on these skills, I wanted to make sure I developed efficient and organized coding practices, so I also took courses in Linux Command Line, Git and Github.`,
};

export const technologyList = [
  { name: "HTML/CSS", image: htmlcss },
  { name: "Javascript", image: javascript },
  { name: "React", image: reactlogo },
  { name: "Node.js", image: node },
  { name: "MongoDB", image: mongoDB },
  { name: "Express", image: express },
  { name: "Jest", image: jest },
  { name: "GraphQL", image: graphQL },
  { name: "Apollo", image: apollo },
  { name: "Git", image: git },
  { name: "Github", image: github },
  { name: "Docker", image: docker },
  { name: "AWS", image: aws },
  { name: "Jira", image: jira },
];
