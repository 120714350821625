import React from "react";
import "../styling/AboutMe.css";
import WorkInquiry from "../components/WorkInquiry";
import Buttons from "../components/Buttons";
import {
  universities,
  licenses,
  pastLife,
  legalAssistant,
  legalCounsel,
  legalIntern,
} from "../personalInfo/pastLifeContent";
import {
  codingCertifications,
  careerChange,
  technologies,
  technologyList,
} from "../personalInfo/codingRelated";
import { credit } from "../personalInfo/myInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  /* faMessage,  */ faPassport,
} from "@fortawesome/free-solid-svg-icons";

export default function AboutMe() {
  return (
    <div className="AboutMe">
      <div className="container-fluid about-me-hero">
        <div className="container about-me-container row">
          <div className="col-lg-6 about-me-description">
            <h1>
              Hi I'm <div>{credit.name}</div>
            </h1>
            <h2>
              {credit.newJobTitle} based in {credit.currentCity}
            </h2>
            <p className="initial-description">
              <strong>{credit.tagLine}</strong>. {credit.intro}
            </p>
            <Buttons button="Download CV" />
          </div>
          <div className="col-lg-6 photo-column">
            <img
              src={credit.photo}
              alt={credit.name}
              className="img-fluid my-photo"
            />
          </div>
        </div>
      </div>
      <div className="Resume">
        <section className="Coding">
          <div className="container">
            <div className="row coding-certifications">
              <div className="col">
                {codingCertifications.map((school, index) => (
                  <div className="school" key={index}>
                    <div className="company">
                      <img
                        src={school.logo}
                        alt="company logo"
                        width={school.width}
                      />
                    </div>
                    <div>
                      {school.certifications.map((cert, index) => (
                        <div key={index}>
                          {cert.name} (
                          {cert.completed ? (
                            <a
                              href={cert.link}
                              className="status"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Certificate
                            </a>
                          ) : (
                            <span className="status">In progress</span>
                          )}
                          )
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col">
                <h2>{careerChange.title}</h2>
                <div className="purple-shape" />
                <p className="gray-text">{careerChange.paragraph}</p>
              </div>
            </div>
            <div className="Technologies">
              <div className="row technology-row">
                <div className="col">
                  <h2>{technologies.title}</h2>
                  <div className="purple-shape" />
                  <p className="gray-text">{technologies.paragraph}</p>
                  <a
                    href="/portfolio"
                    className="button-purple portfolio-button"
                    rel="noreferrer"
                  >
                    View my portfolio
                  </a>
                </div>
                <div className="col tech-background">
                  <div className="tech-row">
                    {technologyList.map((tech, index) => (
                      <div className="tech-col" key={index}>
                        <div className="card">
                          <div className="card-body">
                            <img src={tech.image} alt={tech.name} />
                            <div className="tech-name">{tech.name}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container past-life">
          <div className="row education-row">
            <div className="col-3 education-cards-col">
              {universities.map((uni) => {
                return (
                  <div className="card degree-card" key={uni.degree}>
                    <div className="card-body">
                      <i className={uni.icon}></i>
                      <div className="education-title">{uni.degree}</div>
                      <div className="gray-text">{uni.university}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-3 education-cards-col second-col-edu">
              {licenses.map((license) => {
                return (
                  <div className="card" key={license.title}>
                    <div className="card-body">
                      <i className={license.icon}></i>
                      <div className="education-title">
                        {license.title}
                      </div>{" "}
                      <div className="gray-text">
                        {license.info && license.info}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row smallscreen">
              <h2>{pastLife.title}</h2>
              <div className="purple-shape" />
              <p className="gray-text past-life-paragraph">
                {pastLife.paragraph}
              </p>
            </div>
            <div className="WorkHistory col-6">
              <div className="row work-history-row">
                <h2>{pastLife.title}</h2>
                <div className="purple-shape" />
                <p className="gray-text past-life-paragraph">
                  {pastLife.paragraph}
                </p>
              </div>
              <div className="row">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {legalCounsel.title}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      {legalAssistant.title}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      {legalIntern.title}
                    </button>
                  </li>
                </ul>
                <div className="experience-box">
                  <br />
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <img
                        src={legalCounsel.logo}
                        alt={legalCounsel.imgalt}
                        width="80"
                        className={legalCounsel.imgClassName}
                      />
                      <p>{legalCounsel.description}</p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <img
                        src={legalAssistant.logo}
                        alt={legalAssistant.imgalt}
                        className={legalAssistant.imgClassName}
                        width="80"
                      />
                      <p>{legalAssistant.description}</p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <img
                        src={legalIntern.logo}
                        alt={legalIntern.imgalt}
                        className={legalIntern.imgClassName}
                        width="85"
                      />
                      <br />
                      <div className={legalIntern.className}>
                        <em>{legalIntern.court}</em>
                        <p>{legalIntern.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="practical-info">
        <h2 className="practical-title">Practical info</h2>
        <div className="card ">
          <div className="card-body">
            <FontAwesomeIcon className="icon" icon={faPassport} />{" "}
            {credit.citizenship}
          </div>
        </div>
        {/* <div className="card"> */}
        {/* <div className="card-body"> */}
        {/* <div className="row">
              <div className="col-2">
                <FontAwesomeIcon className="icon" icon={faMessage} />
              </div> */}
        {/* <div className="col">
                {credit.languages.map((language) => {
                  return (
                    <div key={language.language}>
                      {language.language}: {language.level}
                    </div>
                  );
                })}
              </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </section>
      <WorkInquiry />
    </div>
  );
}
