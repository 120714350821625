import KarenStewartCV from "../personalInfo/KarenStewartCV.pdf";
import MyPhoto from "../images/MyPhoto.JPG";

export const personalLinks = [
  {
    title: "Github",
    link: "https://github.com/KarenStewart1",
    icon: "fab fa-github",
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/karenstewartnl/",
    icon: "fab fa-linkedin",
  },
  {
    title: "iamkarenstewart@gmail.com",
    link: "mailto:iamkarenstewart@gmail.com",
    icon: "far fa-envelope",
  },
];

export const credit = {
  name: "Karen Stewart",
  newJobTitle: "Developer",
  currentCity: "Amsterdam",
  link: "https://www.linkedin.com/in/karenstewartnl/",
  intro:
    "I realized it is never too late to change careers when you find something you are truly passionate about.  I'm excited to keep learning, driven by the challenge, and having a lot of fun in the process!",
  tagLine: "Lawyer turned developer",
  cvPDF: KarenStewartCV,
  photo: MyPhoto,
  languages: [
    { language: "English", level: "Native" },
    { language: "Dutch", level: "A2" },
  ],
  citizenship: "Dual Finland & US citizen",
};
